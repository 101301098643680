
import enLocale from 'element-ui/lib/locale/lang/en';
const en = {
  menu: {
    HomePage: 'HomePage',
    Notification: 'Notification',
    ESEDevicePackage: "Device Package",
    MyVehicle: "My Machines",
    FactoryProduct: 'Factory Production',
    MachineTypeManage: 'Machine Type Manage',
    FaultManage: 'Fault Manage',
    FaultAnalysis: 'Fault Analysis',
    RDMManage: 'RDM Manage',
    RDMAssign: 'RDM Assign',
    UserManagement: 'Authentication Management',
    UserManagement_Org: 'Organization',
    UserManagement_User: 'User',
    UserManagement_UserGroup: 'UserGroup',
    UserManagement_Role: 'Role',
    UserManagement_VehicleGroup: 'Machine Group',

    DataAnalysis: "Data Analysis",
    VoltageAnalysis: "Voltage",
    VehicleAnalysis: "Machine",
    EfficencyAnalysis: "Efficency",


    GeoFence: "GeoFence",
    GeoFenceManagement: "Management",
    GeoFenceEvent: "Events",
    
    BasicDataManage: "Basic Data Manage",
    PNManage: "PN manage",
    ParameterManage: "Parameter Manage",
  },
  homepage: {
    showGeoFenceOnMap: 'showGeoFenceOnMap',
    RDMNew:'RDM Used Status',
    BatteryLevelLow:'Machine Voltage Level(Low)',
    BatteryLevelVeryLow:'Machine Voltage Level(Very Low)',
    VehicleFaultTrend:'Machine Fault Trend',
    FaultStatistic:'Fault Statistic',
    VehicleOverview:'Machine Overview',
    MachineFault : 'Fault Machine',
    MachineFaultTips: 'Machine Count Which Have Fault',
    Vehicles : 'Machines',
    Latest5minsVoltage:"Voltage Monitor(last 5 minutes)",
    NoMahcinesOnline:"No Mahcines online",
  },
  common: {
    welcome: 'Welcome',
    input_password: 'Password:',
    input_new_password: 'New Password:',
    check_password: 'please input password',
    check_new_password: 'please input new password',
    check_password_length: 'require 6 characters at least',

    title: "Geo Fence Config",
    selectCompany: "please select company",
    selectMachine: "please select machine",
    selectSoftware: "please select software",
    selectRDM: "please select RDM",
    empty: "no data",
    company: "Company",
    comment: "Comment",
    Tip: 'Tip',
    deleteComfirm: "Are you sure to delete ?",
    deleteComfirmTip: 'Some data exceptions have not been repaired. Do you want to delete and continue?',
    deletedcancel: 'Deletion Cancelled',
    operationSuccess: "Operation Success",
    operationFailure: "Operation Failure",
    machineControlOperationFailure: "Operation failure!Possible reasons:1.This machine donot support this function 2.CAN bus error",
    operation: 'Operation',
    confirm: "Confirm",
    cancel: "Cancel",
    lat: "latitude",
    log: "longitude",
    geo: "geo",
    carNo: "Machine SN",
    carType: "Machine type",
    RDM: "RDM",
    modelNumber: "Model number",
    carState: "Machine state",
    carState0: "Offline",
    carState1: "Online",
    carState_online: 'Online',
    carState_offline: 'Offline',
    carState_error: 'Error',
    carState_fault: 'Fault',
    carKSIState_on: 'On',
    carKSIState_off: 'Off',
    rdmSimState_on: 'On',
    rdmSimState_off: 'Off',
    batteryVoltageStatus_normal: 'Normal',
    batteryVoltageStatus_warn: 'Warning',
    batteryVoltageStatus_error: 'Danger',
    carLockStatus_Lock: 'Locked',
    carLockStatus_UnLock: 'Unlocked',
    lastOnlineTime: "Lastest online time",
    battery: "Battery voltage",
    faultCode: "Fault code",
    geoFenceCmdLimit: "Geo fence limit",
    search: "Search",
    startTime: "Start from",
    endTime: "End with",
    lastWeek: "Last week",
    lastMonth: "Last month",
    all: "All",
    today: "Today",
    thisWeek: "This week",
    thisMonth: "This month",
    thisYear: "This Year",
    mapcode_China: 'China',
    mapcode_India: 'India',
    mapcode_Japan: 'Japan',
    mapcode_USA: 'United States',
    mapcode_desc: 'Worldview Boundaries',
    switch_language: 'English',
    switch_language_ch: 'Switch To Chinese',
    switch_language_en: 'Switch To English',
    logout: 'Sign out',
    password: 'Change Password',
    secondpassword:'Change Double Verify Password',
    timezone: 'Change Timezone',
    select_timezone: 'Timezone:',
    version: 'Version',
    pleaseChoose: 'Please Choose...',
    required: ' Required',
    success: 'Success',
    change_password_success: 'Change password success',
    change_password_error: 'Change password error',
    change_timezone_success: 'Change timezone success',
    change_timezone_error: 'Change timezone error',
    change_language_error: 'Change language error',
    switchCompany: "Switch Company",
    noData: "No Data",
    yes: "Yes",
    no: "No",
    edit: 'Edit',
    delete: 'Delete',
    clone: 'Clone',
    vehicleDetail: 'Machine Detail',
    Upload : 'Upload',
    Uploading:'Uploading',
    ImportedTip1:'Imported successfully',
    ImportedTip2:'he import is successful, but there are some exceptions, the number of exceptions',
    PictureRequire:'Picture type requirements：jpeg、jpg、png',
    ToSelectImage:'Please select a picture file',
    FileRequire:'File type requirements:',
    used:'has been used',
    Off:'Off',
    On:'On',
    loadingMore:'Loading More',
    reset:'Reset',
    startdate:'Start Date',
    enddate:'End Date',
    chooseMachine:'Choose Machine',
    chooseDate:'Choose Datetime',
    Description:'Description',
    selectOnlyOne:'Select at most one',
    CarOnlineCount: "Machine Online Count",
    CarOfflineCount: "Machine Offline Count",
    CarTotalCount: "Machine Total Count",
    RDMOnlineCount: "RDM Online Count",
    RDMOfflineCount: "RDM Offline Count",
    KSIOnlineCount: "Machine KSI Power On Count",
    KSIOfflineCount: "Machine KSI Power Off Count",
    RDMConfigCount: "RDM Used Count",
    RDMUnConfigCount: "RDM Free Count",
    Page_of: "of",
    Pages: "total",
    SEQ:"SEQ",
    Hour:"H",
    Day:"D",
    Second:"S",
    Frequency:"Count",
    Voltage:"V",
    Time:"Time",
    Seconds:"S",
    KSIOnTime:"KSI Time",
    KSIOffTime:"KSI Off Time",
    TractionTime:"Traction Time",
    LiftingTime:"Lifting Time",
    WorkTime:"Work Time",
    FreeTime:"Free Time",
    EfficiencyRate:"Efficiency",
    SearchKeyWord:"Please input parameter name"
  },
  bizConfig: {
    DeviceImportTitle: 'E/SE Series Device Package',
    DeviceName: 'Device Name',
    OSVersion: 'OS Version',
    NewOS: 'New OS',
    Remark: 'Remark',
    confirm: 'Confirm',
    DeviceMenuFile: 'Device Menu File(.csv)',
    VCLCodFile: 'VCL File',
    OSBSLFile: 'OS BSL File',
    VCLBSLFile: 'VCL BSL File',
    OnlyDeviceMenuFile: 'Only can upload xls file which generate by tool 1314(.xls)',
    OnlyVCLCodFile: 'Only can upload VCL code file(.vcl)',
    OnlyOSBSLFile: 'Only can upload OS Bin file(.bsl)',
    OnlyVCLBSLFile: 'Only can upload VCL Bin file(.bsl)',
    OnlyFileEDS: 'Only can upload EDS file(.eds)',
    OnlyFileOS123: 'Only can upload CSV file(.csv)',
    OnlyFileFault: 'Only can upload CSV file(.csv)',
    OnlyFileCanObjectAddr: 'Only can upload CSV file(.csv)',
    AddOs: 'Adding OS Information',
    AddFile: 'Add file',
    RemarkInformation: 'Remark information',
    EDSFile: 'OS original EDS file',
    FileOS123: 'OS original os123xesp.csv',
    FaultFile: 'OS fault file(.csv)',
    CanObjectAddrFile: 'OS CAN Object Information',
    save: 'Save',
    cancle: 'Cancle',
    generate: 'Generate',
    clear: 'Clear',
    NO: 'NO',
    DsviceVersion: 'DeviceVersion',
    Operation: 'Operation',
    OSVerifyMessage:'Please fill in valid OS version',
    OSVerifyMessage2:'OS version already existed, continue to replace?',  
    OSVerifyMessage3:'Success,OS version have generated!', 
    OSResultMessage:'Failed to generate OS version!', 
    DeviceVerifyMessage:'Device Name already existed, continue to replace?',  
    DeviceParameterNullMessage:'Some parameter cannot find CAN address, export the NULL them also？', 
    PackageGenerateMessage:'New package is being generated, please wait...',     
  },
  addMachine: {
    addMachine: 'Add Machine',
    EditMachine: 'Edit Machine',
    setp1: 'Machine Basic Information',
    setp2: 'Machine Devices Configuration',
    setp3: 'Machine Fault Configuration',
    setp4: 'Machine Monitor Configuration',
    MachineSN: 'MachineSN',
    //MachineName: 'MachineName',
    MachineSNExited: 'MachineSN is Exited',
    RDMNO: 'RDM No',
    voltageRange: "Voltage Range",
    voltageRangeRule: "Please enter the correct voltage range",
    voltageRangeTypeRule: "voltage range should be number",
    remark: 'Remark',
    picture: 'Picture',
    uploadPicture: 'Upload pictures',
    Rebulid: 'Rebulid',
    support: 'Support jpg,jpeg,png,JPG,PNG format pictures',
    Preview: 'Preview',
    tip: 'Scroll / drag the mouse to zoom / drag the avatar picture',
    Submit: 'Submit',
    Return: 'Return',
    Next: 'Next',
    Type: 'Type',
    DeviceNodeId: 'NodeId',
    DeviceFile: 'Upload device files',
    Add: 'Add',
    OnlyRDEVFile: 'Only can upload RDEV file(.rdev)',
    OnlyEDEVFile: 'Only can upload EDEV file(.edev)',
    OnlyFDEVFile: 'Only can upload FDEV file(.fdev)',
    exitNodeId: 'NodeId already be used!',
    AddDeviceDialogTitle: 'Add machine device configuration',
    EditDeviceDialogTitle: 'Edit machine device configuration',
    Previous: 'Previous',
    Delete: 'Delete',
    faultSearch: 'Fault Name / Code',
    editFault: 'Edit Fault Information',
    faultName: 'Fault Name',
    faultDisplayName: 'Fault Display Name',
    monitorDialogTitle: 'Machine Monitor',
    displayName: 'Display Name',
    deviceNodeID: 'Device',
    address: 'Parameter Name(Address)',
    frequency: 'Update Frequency',
    openDataAnalysis: 'Open Data Analysis',
    import: 'Import',
    importYourTemplate: 'Please select the template you want to import',
    name: 'Name',
    saveTemplate: 'Save As Template',
    templateName: 'Template Name',
    UsedAsTemplate: 'Save Monitor Configuration',
    templateNameExited: 'TemplateName is Existed',
    NO: 'NO',
    Operation: 'Operation',
    FaultDisplayName: 'Fault Display Name',
    FaultName: 'Fault Name',
    FaultCode: 'Fault Code',
    CANAddress: 'CAN Address',
    Remark: 'Remark',
    Edit: 'Edit',
    DisplayName: 'Display Name',
    ParameterNameAddress: 'Parameter Name (Address)',
    UpdateFrequency: 'Update Frequency',
    DataAnalysis: 'Data Analysis',
    DefaultMachineMonitor: 'Default Machine Monitor',
    MachineMonitor: 'Machine Monitor:',
    NoMoreMonitor: 'No more device monitor informatiom',
    addmonitor: 'addmonitor',
    Device: 'Device',
    Devices: 'Devices:',
    List: 'List',
    FSeries: 'F Series',
    NodeId: 'NodeId',
    FaultList: 'Fault List',
    ImportFromTemplate: 'Import From Template',
    IsDeleteData: 'Sure to delete this data',  
  },
  Error: {
    FileTypeError: 'Wrong File Type',
    MonitorParameterError: '监控参数配置错误',
  },
  login: {
    login: 'login',
    language: '中文',
    check_username: 'Please input your username !',
    check_password1: 'Please input your password !',
    check_password2: 'Please input at least 6 characters',
    message1: 'Successful',
    message2: 'Username or password invalid',
    input_username: 'Username',
    input_password: 'Password',
    remember: 'Remember',
    title1: 'Welcome to RDS',
    title2: 'Monitor smart , Analysis free',
    CompanyLTD:'Curtis Technology (Suzhou) Co., LTD.',
    AllRight:'All rights reserved.',
    //SuICP:'Su ICP',
    //PublicNetWorkSecurity:'Su public network security'
    SuICP:'苏ICP备',
    PublicNetWorkSecurity:'苏公安网备',
    SuUnit:'号',
  },
  notification: {
    MessageCenter: 'Message Center',
    myInformation: 'My Information',
    markAllAsRead: 'Mark all as read',
    markAsRead: 'Mark as read',
    remove: 'Remove',
    type_All: 'All',
    type_error: 'Error',
    type_warning: 'Warning',
    type_notice: 'Info',
    toMessageCenter: 'The message center',
    deleteAll: 'Delete All',
    Noerrorinformation:"No error information",
    Nowarninginformation:"No warning information",
    Nonoticeinformation:"No noticein formation",
    Noinformation:"No message information",
    JustNow:"Just Now",
    MinutesAgo:" Minutes ago",
    HoursAgo:" Hours ago",
    DaysAgo:" Days ago",
  },
  myvehicle: {
    companylist: 'Company List',
    company: 'Company',
    machine: 'Machine',
    check_machinesn: 'Please input machine SN',
    MachineSNExist: 'Machine SN Already Exists',
    select_rdmno: 'Please select ROM No',
    companyName: 'Company Name',
    machineinformation: 'Machine information',
    addMachine: 'Add Machine',
    groupManage: 'Group Manage',
    backList: 'Back List',
    vehicleInformation: 'Machine information',
    vehicleBasicInformation: 'Basic information of machine',
    vehicleFault: 'Machine Fault',
    faultAffectTime: 'Fault Affect Time',
    latestHalfHourBatteryVoltage: 'Latest Half Hour Battery Voltage',
    parameterMonitor: 'Parameter Monitor',
    machineStatus: 'Machine Status',
    rdmStatus: 'RDM Status',
    ksiStatus: 'KSI Status',
    simStatus: 'SIM Status',
    batteryStatus: 'Battery Status',
    chooseGroup: 'Choose Group',
    machineList: 'Machine List',
    batchEdit: 'Batch Edit',
    batchUpgradeSoftware: 'Batch Upgrade Software',
    releaseModel: 'Release model',
    machineType: 'Machine Type',
    measurementClass: 'Measurement Class',
    physicalclass: 'Physical Class',
    timeClass: 'Time Class',
    deviceClass: 'Device Class',
    switchClass: 'Switch Class',
    otherClass: 'Other',
    deviceList: 'Device List',
    systemVersion: 'System Version',
    fieldVersion: 'Field Version',
    latestVersion: 'Latest Version',
    parameterList: 'Parameter List',
    contralModeSeclect: 'Select Device Parameter',
    parameterName: 'Parameter Name',
    minValue: 'Min Value',
    maxValue: 'Max Value',
    units: 'Units',
    paramvalue: 'Value',
    notRange: 'The modified value is out of range!',
    noParam: 'No parameters to modify!',
    class: 'Class',
    mix: 'Mix',
    expand: 'Expand',
    collapse: 'Collapse',
    memberof: 'Member of',
    goodCondition: 'Machine is in good condition!',
    machineDetailInformation: 'Machine Detail Information',
    historyDataAnalysis: 'History Data Analysis',
    parameterQueryAndEdit: 'Parameter Query And Edit',
    machineControl: 'Machine Control',
    machineUpgrade: 'Machine Upgrade',
    operationSuccessWaiting: "Please Waiting Or Please Reset Machine KSI",
    RemoteStatus:'Remote status',
    changeRemoteStatus : 'Change remote status',
    resetRemote : 'Reset remote',
    enableRemote : 'Enable remote',
    disableRemote : 'Disable remote',
    RaiseUpStatus:'Raise up status',
    changeRaiseUpStatus : 'Change  raise up status',
    enableRaiseUp : 'Enable raise up',
    disableRaiseUp : 'Disable raise up',
    changeLimitlevelStatus : 'Change machine limited level',
    limitedMachine: 'Limited machine',
    //changeLockStatus : 'Change machine lock status',
    changeLockAndRaiseUpStatus : 'Change machine lock and raise up status',
    lockMachineStatus : 'Lock status',
    unlockMachine: 'Unlock machine',
    lockMachine: 'Lock machine',
    lockMachineAnddisableRaiseUp : 'Lock machine And Disable raise up',
    unlockMachineAndenableRaiseUp : 'Unlock machine And Enable raise up',
    machineGEOfenceEvent: 'Machine GEO fence Event',
    eventTime: 'Event Time',
    fenceName: 'Fence Name',
    tigger: 'Tigger',
    command: 'Command',
    //confirmUnlockMachine: 'Confirm Unlock Machine',
    //confirmlockMachine: 'Confirm Lock Machine',
    confirmUnlockAndEnableRaiseUpMachine : 'Confirm Unlock Machine And Enable Raise Up',
    confirmlockAndDisableRaiseUpMachine : 'Confirm Lock Machine And Disable Raise Up',
    confirmEnableRemoteStatus : 'Confirm Enable Remote Status',
    confirmDisableRemoteStatus : 'Confirm Disable Remote Status',
    confirmEnableRaiseUpStatus : 'Confirm Enable Raise Up',
    confirmDisableRaiseUpStatus : 'Confirm Disable Raise Up',
    confirmResetRemoteStatus : 'Confirm Reset All Remote Status',
    enter: 'Enter',
    leave: 'Leave',
    nolimitOperation : 'Relieve Limit',
    nolimitChoosed : 'No Limit',
    highlimit: 'Middle',
    middlelimit: 'Low',
    lowlimit: 'Very Low',
    safeCheck: 'Please check machine environment,under safty status?',
    secondaryVerification: 'Secondary password verification',
    limitedSpeed: 'Limited Speed',
    scuFail: 'SCU Failed',
    scuOK : 'SCU success',
    secondaryVerificationFail: 'Password verification failed',
    carStatus: 'Status',
    MachineUpgrade_success: 'Machine upgrade succeeded',
    MachineUpgrade_fail: 'Machine upgrade failed',
    selectSoftwareVersion: 'Select software version',
    addNewSoftware: 'Add New Software',
    Uploaddevicefiles: 'Upload device files',
    Machine_locked: 'Machine was locked!!!',
    Machine_upgraded: 'Machine is being upgraded!!!',
    waiting: 'Please waiting......',
    lockby: 'Locked by {username}',
    totalVersion: 'A total of {total} versions are available',
    fileUploadnotnull: 'Please upload the software of at least one device!',
    parameters: ' parameters',
    softwreUpgradeStatus_Failed: 'Failed',
    softwreUpgradeStatus_Success: 'Success',
    softwreUpgradeStatus_Processing: 'Processing',
    softwreUpgradeStatus_TimeOut: 'TimeOut',
    softwreUpgradeStatus_SCU_Fail: 'SCU_Fail',
    softwreUpgradeStatus_RDMRefuse: 'Failed_RDMRefuse_0x0E',
    softwreUpgradeStatus_File_Summary: 'Failed_File_Summary_0x0F',
    softwreUpgradeStatus_Transfer_File: 'Failed_Transfer_File_0x10_0x12',
    softwreUpgradeStatus_RDMRefuseToUpagrade: 'Failed_RDMRefuseToUpagrade_0x13',
    softwreUpgradeStatus_Upgrade_Interrupt: 'Failed_Upgrade_Interrupt_0x14',
    latestUpgradeFailed: 'Latest upgrade Failed',
    faultStartTime: 'Fault Start Time',
    RDM_offline: 'RDM is offline',
    KSI_ElectricOff: 'machine KSI is OFF',
    lable_range: 'value range(Unit)',
    machineType_Name: 'Machine Type Name',
    TypeQRCode: 'Type QR Code',
    createMachineType: 'Publish Machine Type',
    selectOneMachine: 'Select up to one machine',
    TypeNameExited: 'TypeName already exists',
    loading_limit: 'Is Limit Speed',
    loading_lock: 'Locking machine',
    loading_unlock: 'Unlocking machine',
    loading_enableremote: 'processing enable remote',
    loading_disableremote: 'processing disable remote',
    loading_enableraiseup: 'processing enable raise up',
    loading_disableraiseup: 'processing disable raise up',
    loading_publishtype: 'Is Publish Machine Type',
    loading_editParam: 'Updating Parameter,Please waiting...',
    loading_queryParam: 'Querying parameters,Please waiting...',
    Fault_Statistic: 'Fault Statistic',
    Fault_Affect_Time: 'Fault Affect Time(H)',
    Parametric_Analysis: 'Parametric Analysis',
    Machine_Efficiency: 'Machine Efficiency',
    History_location: 'History location',
    Device_Information: "Device Information",
    SendConfigurationInformationToRDMFail: 'Send Configuration Information To RDM Fail!',
    machineDevice:"Machine Device Information",
    Selected:"Selected",
    DisableParameterQuery: 'Query parameter permission not granted!',
    FaultForMachine:" faults for machine",
    FaultForController:" faults for device ",
    KSIStatusComment:"Not real-time status since RDM is offline",
    exportMachine: 'Export Machines',
  },
  RDMMessage: {
    stopFailed: 'Command to stop failed',
    uploadFailed: 'Command to do PFU failed',
  },
  FactoryProduct: {
    manageMachineType: 'Manage Published Machine Type',
    machineTypeName: 'Machine Type Name',
    editMachineType: 'Edit Machine Type',
    deleteMachineType: 'Delete Machine Type',
    emptyText: 'There is no model information available!',
    addMachineType: 'Add Machine Type',
    goTo: 'Please go to',
    MachineTypeList: 'Machine Type List',
    MachineType: 'Machine Type',
    loading_editMachineType: '正在修改车型English',
    loading_CreateMachine: '正在创建车辆English',
    card: 'Card',
    list: 'List',
    noMachineTypeText: 'There is no model information available, please contact administrator!'
  },
  faultManage: {
    faultList: 'Fault List',
    allfault: 'All Fault',
    allfaultTips: 'Summary Real Time Fault，History Fault and Fault Supplement Record',
    realfault: 'Real Time Fault',
    realfaultTips: 'Faults were reported by RDM module and fault was unresolved',
    systemfault: 'History Fault',
    systemfaultTips: 'Faults were reported by RDM module but fault was resolved',
    userfault: 'Fault Supplement Record',   
    userfaultTips: 'Fault was recorded(unresolved/resolved) by user through [Add Fault Record] fuction and the fault cannot report by RDM module',  
    addFaultRecord: 'Add Fault Record',
    record_user: 'Record User',
    resolved_user: 'Resolved User',
    faultCycle: 'Fault Cycle',
    active: 'active',
    resolved: 'resolved',
    faulttime: 'Fault Time',
    faultStartTime: 'Fault StartTime',
    faultEndTime: 'Fault EndTime',
    faultStatus: 'Fault Status',
    editFaultRecord: 'Edit Fault Record',
    durationAnalysis: 'Fault impact duration analysis',
    tab_total_duration: 'Total fault duration analysis',
    tab_daily_duration: 'Daily fault duration analysis',
    greaterthan_starttime: 'The end time must be greater than the start time',
    FaultFrequencyAnalysis: 'Fault frequency analysis',
    tab_total_frequency: 'Total Fault Frequency Analysis',
    tab_daily_frequency: 'Daily Fault Frequency Analysis',
    Faultdetaillist: 'Fault detail list',
    faultDate: 'Fault Date',
    faultFrequency: 'Fault Frequency',
    maintenance_record: 'Maintenance record',
  },
  RDMManage: {
    RDMManage: 'RDM Manage',
    Model: 'Model',
    RDM_Detail: 'RDM Detail',
    SoftwareManage: 'Software Manage',
    NewSoftware: 'New Software',
    SetDefault: 'Set as Default',
    IsDefault: 'IsDefault',
    UploadTime: 'Upload Time',
    Software: 'Software',
    IsSetDefault: 'Confirm to set the selected software as the default',
    IsSoftware: 'Software already exists',
    NetworkStatus: 'Network Status',
    Assign: 'Assign',
    Operation: 'Operation',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    confirmActivate: 'Confirm to Activate Network',
    confirmDeactivate: 'Confirm to Deactivate Network',
    chooseCompany: 'Choose Company',
    Add: 'Add',
    Delete: 'Delete',
    Edit: 'Edit',
    AssignRDM: 'Assign',
    ModelNumber: "Model",
    ProductNo: "ProductNo",
    DateCode: "DateCode",
    ICCID: "ICCID",
    MISISDN: "MISISDN",
    IMSI: "IMSI",
    RDMNoExist: 'RDM No existed',
    RDMDetail: 'RDM Detail Information',
    RDMParameterManage: 'Parameter Query And Edit',
    RDMSoftwareManage: 'Software Upgrade',
    SoftwareVersion: 'Software Version',
    SoftwareDesc: 'Software Desc',
    SoftwareUploadTime: 'Software Upload Time',
    ServiceProvider: 'Service Provider',
    DataUsage: 'Data Usage',
    SoftwareConfig: 'Config Software',
    SoftwareConfigStatus_Yes: 'Yes',
    SoftwareConfigStatus_No: 'No',
    NetWorkStatus_Normal: 'Normal',
    NetWorkStatus_Disabled: 'Disabled',
    NetWorkStatus_No: 'No NetWork',
    NetWorkStatus_Provider_CMCC: 'CMCC',
    NetWorkStatus_Provider_CTCC: 'CTCC',
    NetWorkStatus_Provider_CUCC: 'CUCC',
    MachineBindStatus: 'Binding Machine Binding',
    MachineBindStatus_Yes: 'Yes',
    MachineBindStatus_No: 'No',
    RDMMenu: 'RDM Menu',
    RDM_upgraded: 'RDM is been upgrading!!!',
    RDM_locked: 'RDM was locked!!!',
    latestUpgradeFailed: 'Latest upgrade Failed',
    selectSoftwareVersion: 'Please select software version',
    totalVersionCount: 'A total of {total} versions are available',
    software_detail: 'Software Detail',
    LatestUpdateTime: 'Latest Update Time',
    UpdateLimitTime: 'Only can be operated once in 3 minutes',
    RDMPNManage: 'PN Manage',
    ProductType:"Product Type",
    ProductType_General:"General",
    ProductType_Particular:"Particular",
    selectPN:"choose product number",
    ThridCloudSupport:"Support third-party clouds",
    ThridCloudSupport_Yes:"Yes",
    ThridCloudSupport_No:"No",
    PNExisted:"Product Numer Exist!",
    RDMSoftwareVersion:"RDM Software Version",
  },
  ...enLocale
};
export default en;
