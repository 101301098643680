<template>
    <div class="machine-control-box" v-loading="loading" :element-loading-text="loadingText">
        <div class="row1">
            <div class="row1-header-box">
                <div class="row1-header-btn" style="float:left">
                     <el-button type="danger" :disabled="!this.privilege.GrantMachineControl||carDetails.CarBundleStatus != ConstDefinition.CarStatus.Online  ? true: false"  @click="resetRemote()">{{$t("myvehicle.resetRemote")}}</el-button> 
                </div>                
                <div class="row1-header-btn">
                     <el-button type="danger" :disabled="!this.privilege.GrantMachineControl||carDetails.CarBundleStatus != ConstDefinition.CarStatus.Online  ? true: false"  @click="changeRemote()">{{
                        carDetails.CarRemoteEnableStatus ==  ConstDefinition.CarRemoteEnableStatus.Enabled ? $t("myvehicle.disableRemote")  : $t("myvehicle.enableRemote")}}</el-button>  

                    <el-button type="primary" :disabled="!this.privilege.GrantMachineControl||carDetails.CarBundleStatus != ConstDefinition.CarStatus.Online 
                    || carDetails.CarRemoteEnableStatus == ConstDefinition.CarRemoteEnableStatus.Disabled
                    || carDetails.CarLockedStatus == ConstDefinition.CarLockStatus.lock ? true: false"  @click="openLimitSpeed()">{{$t("myvehicle.limitedMachine")}}</el-button>

                    <el-button type="warning" :disabled="!this.privilege.GrantMachineControl                    
                    ||carDetails.CarBundleStatus != ConstDefinition.CarStatus.Online ? true: false
                    || carDetails.CarRemoteEnableStatus == ConstDefinition.CarRemoteEnableStatus.Disabled" @click="lockMachine()">
                        {{ carDetails.CarLockedStatus ==  ConstDefinition.CarLockStatus.lock ? $t("myvehicle.unlockMachineAndenableRaiseUp")  : $t("myvehicle.lockMachineAnddisableRaiseUp") }} 

                    </el-button>
                    <!--el-button type="success" :disabled="!this.privilege.GrantMachineControl||carDetails.CarBundleStatus != ConstDefinition.CarStatus.Online
                    || carDetails.CarRemoteEnableStatus == ConstDefinition.CarRemoteEnableStatus.Disabled 
                    || carDetails.CarLockedStatus == ConstDefinition.CarLockStatus.lock ? true: false"  @click="changeRaiseUp()">{{
                        carDetails.CarRaiseUpStatus ==  ConstDefinition.CarRaiseUpStatus.Enabled ? $t("myvehicle.disableRaiseUp")  : $t("myvehicle.enableRaiseUp")}}</el-button-->
                </div>
            </div>
            <div class="row1-body-box" id="row1-body-box"></div>
           <div id="machineStatusTips" style=" position:absolute;top:50px;"></div> 
        </div>
        
        <div class="row3">
            <div class="row3-header-label">{{ $t("myvehicle.machineGEOfenceEvent") }}</div>
            <div class="row3-body-box">
                <div class="row3-body-table">
                    <div class="row3-body-table-box">
                        <el-table
                            :data="tableData"
                            style="width: 100%"
                            max-height="700"
                        >
                            <el-table-column
                                type="index"
                                align="center"
                                label="No"
                                width="50"
                                :index="indexMethod"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="EventTime"
                                align="center"
                                :label='$t("myvehicle.eventTime")'
                                width="200"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="FenceName"
                                align="center"
                                :label='$t("myvehicle.fenceName")'
                            >
                            </el-table-column>
                            <el-table-column
                                :label='$t("myvehicle.tigger")'
                                width="100"
                            >
                            <template slot-scope="scope">
                                <span v-if="scope.row.FenceTrigger == ConstDefinition.FenceTrigger.leave" style="color:#FF4D4F">{{checkExistData(FenceTrigger,scope.row.FenceTrigger, 'value').text}}</span>
                                <span v-else style="color:#00B194">{{checkExistData(FenceTrigger,scope.row.FenceTrigger, 'value').text}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                                prop="FenceRuleCommand"
                                align="center"
                                :label='$t("myvehicle.command")'
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pagination">
                        <div
                            v-if="carSearch.total > userInfo.PageSize"
                            class="paginationCount"
                        >
                            {{
                                (carSearch.page - 1) * userInfo.PageSize + 1
                            }}-{{ carSearch.page * userInfo.PageSize }} 
                            {{ $t("common.Page_of") }} {{ carSearch.total }} {{ $t("common.Pages") }}
                        </div>
                        <div class="paginationDiv">
                            <el-pagination
                                background
                                :page-size="userInfo.PageSize"
                                @current-change="handleCurrentChange"
                                :hide-on-single-page="true"
                                layout="prev, pager, next"
                                :total="carSearch.total"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog 
            :title="$t('myvehicle.limitedSpeed')"
            :visible.sync="limitshow"
            width="504px"
            top="32vh"
            class="el-dialog-custom"
        >
            <el-form label-width="150px"> 
                <el-form-item :label="$t('myvehicle.limitedSpeed')">
                    <el-select v-model="limitedSpeed">
                        <el-option v-for="(item,key) in limitedSpeedOperationOptions" :label="item.label" :value="item.value" :key="key"></el-option>
                    </el-select>
                </el-form-item>
               
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="limitshow = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="openSecondConfirm">{{$t('addMachine.Submit')}}</el-button>
            </span>
        </el-dialog>
        <PopBox v-if="popShow" :details="popDetails"></PopBox>
        <el-dialog :title="$t('myvehicle.secondaryVerification')"
          :visible.sync="dialogVisible"
          width="430px" top="32vh"
          class="el-dialog-custom">
          <el-form ref="secondVerify" :model="secondPassword" label-width="100px">
              <el-form-item
                :label="$t('common.input_password')"
                prop="password"
              >
               <el-input
                    v-model="secondPassword.password" show-password
                    :placeholder="$t('common.check_password')"
                ></el-input>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
                 <el-button @click="dialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" @click="submitVerify" >{{$t('addMachine.Submit')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import "@/assets/css/mapbox-gl.css";
import PopBox from "./components/PopBox.vue";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import MapboxWordViewControl from "@/components/geo/mapboxWordViewControl";
import GeoFenceControl from "@/components/geo/geoFenceControl";
import geoFenceApi from "@/api/autogen/v2/geoFence";
import carApi from "@/api/autogen/v2/carList";
import geoApi from "@/api/autogen/v2/cargeo";
import { ConstDefinition } from "@/utils/constDefinition";
import { checkExistData,ifGrantedTheOperationForMyMachineFunction } from '@/utils';

export default {
    components: {
        PopBox,
    },
    props: {
        companyId: {
            type: String,
            default: '',
        },
        machineId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            popShow: false,
            defaultMapCenterPoint: [120.58586216387619, 31.203448584425516], //默认地图中心点的位置
            defaultMapShowLevel: 4, //默认地图显示级别
            mapInstance: null, //地图对象
            flag_showGeoFenceAsMarkerOnMap: false, //是否在地图上显示电子围栏
            geoFencePolygonList: [], // 电子围栏polyline对象列表
            tableData: [],
            carSearch: {
                page: 1,
                total: 0,
            },
            FenceTrigger:[
                {
                    value : ConstDefinition.FenceTrigger.enter,
                    text : this.$t("myvehicle.enter"),
                },
                {
                    value : ConstDefinition.FenceTrigger.leave,
                    text : this.$t("myvehicle.leave")
                }
            ],
            ConstDefinition :ConstDefinition,
            carDetails: {},
            customShow: false,
            //1提示2为表单
            popType: 1,
            limitedSpeed: 3,
            limitedSpeedOperationOptions: [
                {
                    label: this.$t("myvehicle.nolimitOperation"),
                    value: ConstDefinition.CarLimitedStatus.nolimit,
                },
                {
                    label: this.$t("myvehicle.highlimit"),
                    value: ConstDefinition.CarLimitedStatus.high,
                },
                {
                    label: this.$t("myvehicle.middlelimit"),
                    value: ConstDefinition.CarLimitedStatus.middle,
                },
                {
                    label: this.$t("myvehicle.lowlimit"),
                    value: ConstDefinition.CarLimitedStatus.low,
                },
            ],
            limitedSpeedSelectedOptions: [
                {
                    label: this.$t("myvehicle.nolimitChoosed"),
                    value: ConstDefinition.CarLimitedStatus.nolimit,
                },
                {
                    label: this.$t("myvehicle.highlimit"),
                    value: ConstDefinition.CarLimitedStatus.high,
                },
                {
                    label: this.$t("myvehicle.middlelimit"),
                    value: ConstDefinition.CarLimitedStatus.middle,
                },
                {
                    label: this.$t("myvehicle.lowlimit"),
                    value: ConstDefinition.CarLimitedStatus.low,
                },
            ],
            loading: false,
            carAddress: null, //车辆位置标记
            markConfig: {
                //正常
                normal: {
                    color: '#007AC2',
                    background: '#1286C4',
                    rectBack: '#6BB0A6',
                    text: this.$t("common.carState_online"),
                },
                //锁车
                passing: {
                    color: '#FF9A30',
                    background: '#FDA140',
                    rectBack: '#E5BC65',
                    text: this.$t("common.carLockStatus_Lock"),
                },
                //离线
                offline: {
                    color: '#9A9A9A',
                    background: '#747371',
                    rectBack: '#B1BC99',
                    text: this.$t("common.carState_offline"),
                },
                //故障
                fault: {
                    color: '#FF3030',
                    background: '#FD423F',
                    rectBack: '#E58965',
                    text: this.$t("common.carState_fault"),
                },
                //远程功能关闭
                remoteDisable: {
                    color: '#e57b06',
                    background: '#e57b06',
                    rectBack: '#B1BC99',
                    text: this.$t("common.Off"),
                },
            },
               //弹出框内容
            popDetails: {
                title: '',
                desc: '',
                detail:'',
                type: 0
            },
            dialogVisible:false,
            limitshow :false,
            secondPassword :{
                password:''
            },
            privilege: {
                GrantMachineControl:false,
            },
            timer: null,
        };
    },
    
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        loadingText(){
            let text = '';
           if(this.popType === 1){
                if(this.carDetails.CarLockedStatus == ConstDefinition.CarLockStatus.lock){
                    text = this.$t('myvehicle.loading_unlock');
                }else{
                    text = this.$t('myvehicle.loading_lock');
                }
                
            }else if(this.popType === 2){
                text = this.$t('myvehicle.loading_limit');
            }else if(this.popType === 3){
                 if(this.carDetails.CarRemoteEnableStatus == ConstDefinition.CarRemoteEnableStatus.Enabled){
                    text = this.$t('myvehicle.loading_disableremote');
                }else{
                    text = this.$t('myvehicle.loading_enableremote');
                }
            }else if(this.popType === 4){
                 if(this.carDetails.CarRaiseUpStatus == ConstDefinition.CarRaiseUpStatus.Enabled){
                    text = this.$t('myvehicle.loading_disableraiseup');
                }else{
                    text = this.$t('myvehicle.loading_enableraiseup');
                }
            }
            else {
                 text = this.$t('myvehicle.loading_limit');
            }
            return text;
        },
        markConfigValue() {
            let value = 'normal',
                status = this.carDetails.CarBundleStatus,
                lockedStatus = this.carDetails.CarLockedStatus;
            
            if(status == ConstDefinition.CarStatus.Error) {
                value = 'fault';
            } else if(status == ConstDefinition.CarStatus.Offline) {
                value = 'offline';
            } else if(status == ConstDefinition.CarStatus.Online) {
                if(lockedStatus == ConstDefinition.CarLockStatus.lock) {
                    value = 'passing';
                }else if(this.carDetails.CarRemoteEnableStatus ==  ConstDefinition.CarRemoteEnableStatus.Disabled)
                {
                     value = 'remoteDisable';
                }                
            }
            
            return value;
        },
    },
    created() {
        // this.getCarDetails()
        this.getMachineLockStatus();
        this.initialFunctionOperationPrivilege();
        this.initTime();
        console.log("this.initTime=====");
    },
    methods: {
        ifGrantedTheOperationForMyMachineFunction,
        initialFunctionOperationPrivilege()
        {
            this.privilege.GrantMachineControl = ifGrantedTheOperationForMyMachineFunction("MachineControl");          
        },
        //车辆锁定&升级
        getMachineLockStatus() {
            let params = {
                machineId: this.machineId,
            };
            carApi.getMachineLockStatus(params).then((res) => {
                if (res.IsSuccess) {
                    let { IsLocked, IsLockedBySoftwreUpgrade } = res.Data;
                    
                    if (IsLocked && !IsLockedBySoftwreUpgrade) {
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("myvehicle.Machine_locked"),
                            desc: this.$t("myvehicle.waiting"),
                            detail:this.$t("myvehicle.lockby",{username:res.Data.LockedUserName}),
                            type: 1,
                        };
                    } else if(IsLocked && IsLockedBySoftwreUpgrade){
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("myvehicle.Machine_upgraded"),
                            desc: this.$t("myvehicle.waiting"),
                            detail: '',
                            type: 2
                        };
                    } else {
                        this.getCarDetails();
                    }
                }
            });
        },

        //分页序号连续
        indexMethod(index) {
            index = (index + 1) + (this.carSearch.page - 1) * this.userInfo.PageSize;
            return index;
        },

        /**
         * @desc 获取车辆列表详情
         */
        getCarDetails() {
            let params = {
                companyId: this.companyId,
                machineId: this.machineId,
            };

            carApi.getCarDetails(params).then((res) => {
                this.carDetails = res.Data;
                let lockCreen=false;
                //RDM离线
                if(this.carDetails.CarRDMStatus == ConstDefinition.CarStatus.Offline){
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.RDM_offline"),
                        desc: this.$t("myvehicle.waiting"),
                        detail:'',
                        type: 1,
                    };
                    return;
                }
                //ksi断电
                if(this.carDetails.CarKSIStatus == ConstDefinition.KSIStatus.ElectricOff){
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.KSI_ElectricOff"),
                        desc: this.$t("myvehicle.waiting"),
                        detail:'',
                        type: 1,
                    };
                    return;
                }
                console.log("this.carDetails from machine control=====");
                console.log(this.carDetails);

                //重置远程状态，所有状态都恢复到初始状态。RemoteStatus=开启，LimitLevel=unlimit，LocksStatus=unlock，RaiseUpStatus=开启。所有的状态都在WIP中，则证明在重置
                if(this.carDetails.CarProcessChangeRemoteStatus==ConstDefinition.CarRemoteEnableResult.Processing
                &&this.carDetails.CarLockedProcessStatus==ConstDefinition.CarLockResult.Processing
                &&this.carDetails.CarSpeedLimitedLevelProcessStatus==ConstDefinition.CarLimitedResult.Processing
                &&this.carDetails.CarProcessChangeRaiseUpStatus==ConstDefinition.CarRaiseUpResult.Processing
                )
                {
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.resetRemote"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    return;
                }

                //使能位是否在开启中
                if(this.carDetails.CarProcessChangeRemoteStatus==ConstDefinition.CarRemoteEnableResult.Processing)
                {
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.changeRemoteStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    return;
                }

                //使能位是否在锁车过程中和使能位是否在禁止起升过程中
                if(this.carDetails.CarLockedProcessStatus==ConstDefinition.CarLockResult.Processing
                ||this.carDetails.CarProcessChangeRaiseUpStatus==ConstDefinition.CarRaiseUpResult.Processing)
                {
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.changeLockAndRaiseUpStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    return;
                }

                //使能位是否在限速过程中
                if(this.carDetails.CarSpeedLimitedLevelProcessStatus==ConstDefinition.CarLimitedResult.Processing)
                {
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.changeLimitlevelStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    return;
                }

                //使能位是否在禁止起升过程中
                /*if(this.carDetails.CarProcessChangeRaiseUpStatus==ConstDefinition.CarRaiseUpResult.Processing)
                {
                    this.popShow = true;
                    lockCreen=true;
                    this.popDetails = {
                        title: this.$t("myvehicle.changeRaiseUpStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    return;
                }*/

                this.limitedSpeed = res.Data.CarLimitedStatus;
                this.setAsCurrentCar();
                this.queryEvents();
                var html=this.getHtml();
                //console.log("html=====",html);

                // 创建div元素
                var floatDiv = document.getElementById('machineStatusTips');
                
                // 设置div的内容
                floatDiv.innerHTML =html;

                if(!lockCreen)
                {
                    this.popShow = false;
                    //this.clearTime();//不清除，因为车辆状态可能会被手持更改，所有需要页面实时刷新
                    //console.log("this.clearTime=====");
                }
               
 
            });
        },        
        checkExistData(array = [], value = '', key = '') {
            let data = checkExistData(array, value, key);

            if(data.isExist) {
                return data.data;
            } else {
                return {
                    [key]: value,
                };
            }
        },
        /**
         * @desc 获取操作列表
         */
        queryEvents() {
            let params = {
                companyId: this.companyId,
                carNo: this.carDetails.MachineSN,
                pageParams: {
                    pageSize: this.userInfo.PageSize,
                    pageIndex: this.carSearch.page,
                }
            };

            carApi.queryEvents(params).then((res) => {
                this.carSearch.total = res.TotalCount;
                this.tableData = res.Data;
            });
        },
        /**
         * @desc 分页
         */
        handleCurrentChange(val) {
            this.carSearch.page = val;
            this.queryEvents();
        },
        //获取地图位置
        setAsCurrentCar() {
            let self = this;

            var device = {
                deviceSerialNumber: self.carDetails.RDMNO,
            };
            geoApi.getCarLocation(device).then((res) => {
                var local = res.Data;
                //清除标记
                if (self.carAddress != null) {
                    self.carAddress.remove();
                }
                //添加标记
                self.carAddress = new mapboxgl.Marker({ color: this.markConfig[this.markConfigValue].color })
                .setLngLat([local.LongitudeShowValue, local.LatitudeShowValue])
                //.setPopup(new mapboxgl.Popup({offset:25,anchor:'left'})
                //.setHTML(this.getHtml()))
                .addTo(self.mapInstance);
                //设置级别
                self.mapInstance.setZoom(this.defaultMapShowLevel);
                //设置中心点
                self.mapInstance.setCenter([
                    local.LongitudeShowValue,
                    local.LatitudeShowValue,
                ]);
            });
        },
        //返回对应的html
        getHtml() {
            let obj = this.markConfig[this.markConfigValue];
            let html = `<div class="mark-content-box" style="background: ${obj.background}">
                <div class="mark-content-title">${this.carDetails.MachineSN}</div>
                ${this.markConfigValue == 'remoteDisable'?`<div>${this.$t("myvehicle.RemoteStatus")}`:`<div>${this.$t("myvehicle.carStatus")}`} 
                : ${obj.text}</div>
                ${`<div>${this.$t("myvehicle.RemoteStatus")}:${this.carDetails.CarRemoteEnableStatus ==  ConstDefinition.CarRemoteEnableStatus.Enabled ? this.$t("common.On")  : this.$t("common.Off")} </div>`}                                 
                ${`<div>${this.$t("myvehicle.limitedSpeed")}: ${this.checkExistData(this.limitedSpeedSelectedOptions, this.carDetails.CarLimitedStatus, 'value').label}</div>`}
                <div>${this.$t("myvehicle.lockMachineStatus")}:
               ${ this.carDetails.CarLockedStatus ==  ConstDefinition.CarLockStatus.lock ? this.$t("common.carLockStatus_Lock")  : this.$t("common.carLockStatus_UnLock")}</div>
                ${`<div>${this.$t("myvehicle.RaiseUpStatus")}:${this.carDetails.CarRaiseUpStatus ==  ConstDefinition.CarRaiseUpStatus.Enabled ? this.$t("common.On")  : this.$t("common.Off")} </div>`}
            </div>
            `;

                //V1=============================
                /*${this.markConfigValue == 'normal' || this.markConfigValue == 'fault' ? `<div>${this.$t("myvehicle.RemoteStatus")}:${this.carDetails.CarRemoteEnableStatus ==  ConstDefinition.CarRemoteEnableStatus.Enabled ? this.$t("common.On")  : this.$t("common.Off")} </div>` : ''}                                 
                ${this.markConfigValue == 'normal' || this.markConfigValue == 'fault' ? `<div>${this.$t("myvehicle.limitedSpeed")}: ${this.checkExistData(this.limitedSpeedSelectedOptions, this.carDetails.CarLimitedStatus, 'value').label}</div>` : ''}
                <div>${this.$t("myvehicle.lockMachineStatus")}:
               ${ this.carDetails.CarLockedStatus ==  ConstDefinition.CarLockStatus.lock ? this.$t("common.carLockStatus_Lock")  : this.$t("common.carLockStatus_UnLock")}</div>
                ${this.markConfigValue == 'normal' || this.markConfigValue == 'fault' ? `<div>${this.$t("myvehicle.RaiseUpStatus")}:${this.carDetails.CarRaiseUpStatus ==  ConstDefinition.CarRaiseUpStatus.Enabled ? this.$t("common.On")  : this.$t("common.Off")} </div>` : ''}*/
                
            return html;
        },
        //初始化地图
        initmap() {
            mapboxgl.accessToken =
                "pk.eyJ1IjoiNjI2NjgwMTA4IiwiYSI6ImNrb3RyNjQwdDA1eXMzMW12Z2hvYm5leXIifQ.CegbhRdTZq4v4cRw-aCDdQ"; //这里请换成自己的token
            this.mapInstance = new mapboxgl.Map({
                container: "row1-body-box", // container id 绑定的组件的id
                style: "mapbox://styles/mapbox/streets-v11", //地图样式，可以使用官网预定义的样式,也可以自定义
                center: this.defaultMapCenterPoint, // 初始坐标系，这个是南京建邺附近
                zoom: this.defaultMapShowLevel, // starting zoom 地图初始的拉伸比例
                pitch: 0, //地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
                bearing: 0, //地图的初始方向，值是北的逆时针度数，默认是0，即是正北
                antialias: false, //抗锯齿，通过false关闭提升性能
            });

            // Add zoom and rotation controls to the map.
            this.mapInstance.addControl(new mapboxgl.NavigationControl());
            //添加定位的control
            /*this.mapInstance.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true,
                    },
                    trackUserLocation: true,
                })
            );*/

            //添加language控件
            var languageFlag = sessionStorage.getItem("language");
            if (languageFlag == "ch") {
                languageFlag = "zh-Hans";
            }
            if (mapboxgl.getRTLTextPluginStatus() !== "loaded") {
                mapboxgl.setRTLTextPlugin(
                    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.0/mapbox-gl-rtl-text.js"
                );
            }
            this.mapInstance.addControl(
                new MapboxLanguage({
                    defaultLanguage: languageFlag,
                })
            );
            this.addWorldViewControl();
            //添加一个自定义的控件， 用于控制是否显示电子围栏
            this.addControl4ShowGeoFence();
        },
        addWorldViewControl() {
            var self = this;
            var availableWorldviews = [
                {
                code: "CN",
                name: "common.mapcode_China",
                },
                // {
                //   code: 'IN',
                //   name: 'common.mapcode_India'
                // },
                // {
                //   code: 'JP',
                //   name: 'common.mapcode_Japan'
                // },
                //{
                //code: "US",
                //name: "common.mapcode_USA",
                //},
            ];
            //var worldviewOnMapLoad = "US";
            var worldviewOnMapLoad = "CN";
            for (let index = 0; index < availableWorldviews.length; index++) {
                const element = availableWorldviews[index];
                if (element.code == worldviewOnMapLoad) {
                element.checked = true;
                } else {
                element.checked = false;
                }
                element.displayName = self.$t(element.name);
            }
            var MapboxWordViewCtl = new MapboxWordViewControl();
            self.mapInstance.addControl(MapboxWordViewCtl);
            var wordViewConfig = {
                id: "mapInstance",
                title: self.$t("common.mapcode_desc"),
                wordViewArray: availableWorldviews,
            };
            MapboxWordViewCtl.setWordView(wordViewConfig, function (evt) {
                // console.log(evt);
                if (evt.target.value) {
                self.switchWorldview(evt.target.value);
                }
            });

            // Wait for the map to finish loading.
            this.mapInstance.on("load", function () {
                self.filterLayers(self.mapInstance, worldviewOnMapLoad);
            });
        },
        addControl4ShowGeoFence() {
            var self = this;

            var config = { title: self.$t("homepage.showGeoFenceOnMap") };
            //add to map
            var geoFenceCtr = new GeoFenceControl();
            self.mapInstance.addControl(geoFenceCtr);
            geoFenceCtr.setView(config, function (evt) {
                self.flag_showGeoFenceAsMarkerOnMap = evt.target.checked;
                self.showGeoFenceAsMarkerOnMap();
            });
        },
        switchWorldview(worldview) {
            this.filterLayers(this.mapInstance, worldview);
        },

        filterLayers(map, worldview) {
            // The "admin-0-boundary-disputed" layer shows boundaries
            // at this level that are known to be disputed.
            map.setFilter("admin-0-boundary-disputed", [
                "all",
                ["==", ["get", "disputed"], "true"],
                ["==", ["get", "admin_level"], 0],
                ["==", ["get", "maritime"], "false"],
                ["match", ["get", "worldview"], ["all", worldview], true, false],
            ]);
            // The "admin-0-boundary" layer shows all boundaries at
            // this level that are not disputed.
            map.setFilter("admin-0-boundary", [
                "all",
                ["==", ["get", "admin_level"], 0],
                ["==", ["get", "disputed"], "false"],
                ["==", ["get", "maritime"], "false"],
                ["match", ["get", "worldview"], ["all", worldview], true, false],
            ]);
            // The "admin-0-boundary-bg" layer helps features in both
            // "admin-0-boundary" and "admin-0-boundary-disputed" stand
            // out visually.
            map.setFilter("admin-0-boundary-bg", [
                "all",
                ["==", ["get", "admin_level"], 0],
                ["==", ["get", "maritime"], "false"],
                ["match", ["get", "worldview"], ["all", worldview], true, false],
            ]);
        },

        showGeoFenceAsMarkerOnMap() {
            var self = this;

            // //清理geoFence polygon
            for (let index = 0; index < this.geoFencePolygonList.length; index++) {
                const item = this.geoFencePolygonList[index];
                this.mapInstance.removeLayer(item.polygon);
                this.mapInstance.removeSource(item.source);

                var layerId = item.polygon;
                self.mapInstance.off("click", layerId, self.showPopupForLayer);
            }
            this.geoFencePolygonList = [];

            if (self.flag_showGeoFenceAsMarkerOnMap == false) {
                return;
            }

            var param = {
                companyId: this.carDetails.CompanyId,
            };
            if (param.companyId == null) {
                return;
            }
            geoFenceApi
                .getFences(param)
                .then((res) => {
                console.log(res);
                var result = res;
                if (result.IsSuccess) {
                    var geoFenceList = result.Data;
                    for (let index = 0; index < geoFenceList.length; index++) {
                    const geoFence = geoFenceList[index];

                    if (geoFence.GeoPath) {
                        var geoPath = JSON.parse(geoFence.GeoPath);
                        var coordinates = [];
                        for (let index = 0; index < geoPath.length; index++) {
                        const geoPoint = geoPath[index];
                        coordinates.push([geoPoint.Longitude, geoPoint.Latitude]);
                        }
                        var sourceId = "source" + geoFence.FenceId;
                        var layerId = "polygon" + geoFence.FenceId;
                        // Add a data source containing GeoJSON data.
                        self.mapInstance.addSource(sourceId, {
                        type: "geojson",
                        data: {
                            type: "Feature",
                            geometry: {
                            type: "Polygon",
                            // These coordinates outline Maine.
                            coordinates: [coordinates],
                            },
                            properties: {
                            description: geoFence.FenceName,
                            },
                        },
                        });
                        // Add a new layer to visualize the polygon.

                        self.mapInstance.addLayer({
                        id: layerId,
                        type: "fill",
                        source: "source" + geoFence.FenceId, // reference the data source
                        layout: {},
                        paint: {
                            "fill-color": this.markConfig[this.markConfigValue].color,
                            "fill-opacity": 0.3,
                        },
                        });

                        self.mapInstance.on("click", layerId, self.showPopupForLayer);

                        this.geoFencePolygonList.push({
                        fenceId: geoFence.FenceId,
                        polygon: layerId,
                        source: sourceId,
                        coordinates: coordinates,
                        });
                    }
                    }

                    // zoom the map to the polygon
                    if (this.geoFencePolygonList.length > 0) {
                    self.mapInstance.fitBounds(
                        this.geoFencePolygonList[0].coordinates
                    );
                    }
                }
                })
                .catch(function (error) {
                console.log(error);
                });
        },
        //使能远程开启和关闭
        changeRemote() {
            this.clearTime();//清除，否则页面操作数据会一直根据数据更新
            console.log("this.clearTime=====");
            if(this.carDetails.CarBundleStatus != 1) {
                return;
            }
            this.popType = 3;
            let content = '';
            if(this.carDetails.CarRemoteEnableStatus == 1){
                content = this.$t("myvehicle.confirmDisableRemoteStatus") + '?';
            }else{
                content = this.$t("myvehicle.confirmEnableRemoteStatus") + '?';
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {                
                this.openSecondConfirm();
            }).catch(() => {       
            });
        },
        //禁用起升
        changeRaiseUp() {
            this.clearTime();//清除，否则页面操作数据会一直根据数据更新
            console.log("this.clearTime=====");
            if(this.carDetails.CarBundleStatus != 1) {
                return;
            }
            this.popType = 4;
            let content = '';
            if(this.carDetails.CarRaiseUpStatus == 1){
                content = this.$t("myvehicle.confirmDisableRaiseUpStatus") + '?';
            }else{
                content = this.$t("myvehicle.confirmEnableRaiseUpStatus") + '?';
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.openSecondConfirm();
            }).catch(() => {       
            });
        },
        //锁定状态更改
        lockMachine() {
            this.clearTime();//清除，否则页面操作数据会一直根据数据更新
            console.log("this.clearTime=====");
            if(this.carDetails.CarBundleStatus != 1) {
                return;
            }
            this.popType = 1;
            let content = '';
            if(this.carDetails.CarLockedStatus == 1){
                //content = this.$t("myvehicle.confirmUnlockMachine") + '?';
                content = this.$t("myvehicle.confirmUnlockAndEnableRaiseUpMachine") + '?';
            }else{
                //content = this.$t("myvehicle.confirmlockMachine") + '?';
                content = this.$t("myvehicle.confirmlockAndDisableRaiseUpMachine") + '?';
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.openSecondConfirm();
            }).catch(() => {       
            });
        },
        //打开限速
        openLimitSpeed(){
            this.clearTime();//清除，否则页面操作数据会一直根据数据更新
            console.log("this.clearTime=====");
            this.limitshow = true;
            this.popType = 2;
        },
        //重置远程状态，所有状态都恢复到初始状态。RemoteStatus=开启，LimitLevel=unlimit，LocksStatus=unlock，RaiseUpStatus=开启。
        resetRemote() {
            this.clearTime();//清除，否则页面操作数据会一直根据数据更新
            console.log("this.clearTime=====");
            this.popType = 5;
            let content = this.$t("myvehicle.confirmResetRemoteStatus") + '?';
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {                
                this.openSecondConfirm();
            }).catch(() => {       
            });
        },
        //打开二次验证确认
        openSecondConfirm() {

            /*
            //不需要安全检查，因为命令要等到KSI重启后才生效
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.safeCheck")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.dialogVisible = true;
            }).catch(() => {       
            });*/
             this.dialogVisible = true;
        },
        //车辆锁定解锁状态
        lockStatus() {
            // this.loading = true
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO,
                isLocked: this.carDetails.CarLockedStatus == 1 ? false : true,
            };
            let Url = '';
            let content = '';

            carApi.lockStatus(params).then((res) => {
                
                this.customShow = false;
                this.loading = false;
                if(res.IsSuccess){

                    this.popShow = true;
                    this.popDetails = {
                        title: this.$t("myvehicle.changeLockAndRaiseUpStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                   
                    
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.machineControlOperationFailure");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
               
                this.initTime();
                console.log("lockStatus this.initTime=====");   
            })
            .catch(() => {
                Url = require("@/assets/image/error_76.png");
                content= this.$t("common.machineControlOperationFailure");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                this.dialogVisible = false;
                this.loading = false;
                this.initTime();
                console.log("lockStatus this.initTime=====");   
            });
        },
        //车辆限速
        setLimitedSpeed() {
            // this.loading = true
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO,
                IsLimited: this.limitedSpeed == 3 ? false : true,
                limitedLevel: this.limitedSpeed,
            };
            console.log("this.limitedSpeed==" +this.limitedSpeed);
            let Url = '';
            let content = '';
            carApi.limitedSpeed(params).then((res) => {
                
                this.loading = false;
               
                if(res.IsSuccess){

                    this.popShow = true;
                    /*this.popDetails = {
                        title: this.$t("myvehicle.changeLimitlevelStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };*/
                    this.popDetails = {
                        title: this.$t("myvehicle.changeLockAndRaiseUpStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    
                }else{
                     Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.machineControlOperationFailure");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
                
                this.initTime();
                console.log("setLimitedSpeed this.initTime====="); 
                
            })
            .catch(() => {
                Url = require("@/assets/image/error_76.png");
                content= this.$t("common.machineControlOperationFailure");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                this.dialogVisible = false;
                this.loading = false;
                this.initTime();
                console.log("setLimitedSpeed this.initTime====="); 
            });
        },

        //车辆开启关闭远程功能
        changeRemoteStatus() {
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO,
                IsEnableRemoteStatus: this.carDetails.CarRemoteEnableStatus == 1 ? false : true,
            };
            let Url = '';
            let content = '';

            carApi.changeRemoteStatus(params).then((res) => {
                
                this.customShow = false;
                this.loading = false;
                if(res.IsSuccess){

                    this.popShow = true;
                    this.popDetails = {
                        title: this.$t("myvehicle.changeRemoteStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    }; 

                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.machineControlOperationFailure");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
               
                this.initTime();
                console.log("changeRemoteStatus this.initTime====="); 
            })
            .catch(() => {
                Url = require("@/assets/image/error_76.png");
                content= this.$t("common.machineControlOperationFailure");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                this.dialogVisible = false;
                this.loading = false;
                this.initTime();
                console.log("changeRemoteStatus this.initTime====="); 
            });
        },

        //车辆禁用起升功能
        changeRaiseUpStatus() {
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO,
                IsEnableRaiseUp: this.carDetails.CarRaiseUpStatus == 1 ? false : true,
            };
            let Url = '';
            let content = '';

            carApi.changeRaiseUpStatus(params).then((res) => {
                
                this.customShow = false;
                this.loading = false;
                if(res.IsSuccess){

                   this.popShow = true;
                   /*this.popDetails = {
                        title: this.$t("myvehicle.changeRaiseUpStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };*/
                    this.popDetails = {
                        title: this.$t("myvehicle.changeLockAndRaiseUpStatus"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    
                    
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.machineControlOperationFailure");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }

                this.initTime();
                console.log("changeRaiseUpStatus this.initTime====="); 
                
            })
            .catch(() => {
                Url = require("@/assets/image/error_76.png");
                content= this.$t("common.machineControlOperationFailure");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                this.dialogVisible = false;
                this.loading = false;
                this.initTime();
                console.log("changeRaiseUpStatus this.initTime====="); 
            });
        },
        //重置远程状态，所有状态都恢复到初始状态。RemoteStatus=开启，LimitLevel=unlimit，LocksStatus=unlock，RaiseUpStatus=开启。
        resetRemoteStatus() {
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO
            };
            let Url = '';
            let content = '';

            carApi.resetRemoteStatus(params).then((res) => {
                
                this.customShow = false;
                this.loading = false;
                if(res.IsSuccess){

                   this.popShow = true;
                    this.popDetails = {
                        title: this.$t("myvehicle.resetRemote"),
                        desc: this.$t("myvehicle.operationSuccessWaiting"),
                        detail: '',
                        type: 1
                    };
                    
                    
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.machineControlOperationFailure");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }

                this.initTime();
                console.log("resetRemote this.initTime====="); 
                
            })
            .catch(() => {
                Url = require("@/assets/image/error_76.png");
                content= this.$t("common.machineControlOperationFailure");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                this.dialogVisible = false;
                this.loading = false;
                this.initTime();
                console.log("resetRemote this.initTime====="); 
            });
        },
        //初始化定时器
        initTime() {
            this.timer = window.setInterval(() => {      
               this.getCarDetails();                                       
               //获取车辆远程、锁车、限速、起升状态
            }, 10000);
        },

        //清除定时器
        clearTime() {
            window.clearInterval(this.timer);
            this.timer = null;
        },
        //二次验证
        submitVerify(){
            this.loading = true;
            let params = {
                userId: this.userInfo.UserId,
                userAccount: this.userInfo.UserAccount,
                password: this.secondPassword.password,
            };
            carApi.SecondPasswordCheckForModify(params).then((res) => {
                if(res.Data){
                    //不需要安全检查，因为命令要等到KSI重启后才生效
                    //this.safecheck();
                    this.dialogVisible = false;
                    this.limitshow =false;
                    let type = this.popType;
                    if(type == 1) {//锁车和禁止起升一起做
                        this.lockStatus();
                        this.changeRaiseUpStatus();
                    } else  if(type == 2) {
                        this.setLimitedSpeed();
                    }else  if(type == 3) {//使能远程操作功能
                        this.changeRemoteStatus();                        
                    }
                    //else  if(type == 4) {//禁止起升
                        //this.changeRaiseUpStatus();
                    //}
                    else  if(type == 5) {//重置远程状态，所有状态都恢复到初始状态。RemoteStatus=开启，LimitLevel=unlimit，LocksStatus=unlock，RaiseUpStatus=开启。
                        this.resetRemoteStatus();
                    }else{
                        const Url = require("@/assets/image/error_76.png");
                        this.$alert("<img src=" + Url + "><p class='text'>"+ this.$t("common.operationFailure")+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: this.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        this.loading = false;
                    }
                }else{
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.secondaryVerificationFail")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                    this.loading = false;
                }
                
            }).catch(() => {
                this.loading = false;
            });
        },
        /*
        //不需要安全检查，因为命令要等到KSI重启后才生效
        //安全检查
        safecheck(){
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO,
                machineSN: this.carDetails.MachineSN,
                companyId: this.companyId,
            };
            carApi.CommandToGetSCUStatus(params).then((res) => {
                if(res.Data){
                    this.dialogVisible = false;
                    this.limitshow =false;
                    let type = this.popType;
                    if(type == 1) {
                        this.lockStatus();
                    } else  if(type == 2) {
                        this.setLimitedSpeed();
                    }else  if(type == 3) {//使能远程操作功能
                        this.changeRemoteStatus();                        
                    }else  if(type == 4) {//禁止起升供
                        this.changeRaiseUpStatus();
                    }else{
                        const Url = require("@/assets/image/error_76.png");
                        this.$alert("<img src=" + Url + "><p class='text'>"+ this.$t("common.operationFailure")+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: this.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        this.loading = false;
                    }
                }else{
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.scuFail")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                    this.loading = false;
                }
            });
        },*/

        dialogCancel(){
            this.clearForm();
            this.dialogVisible = false;
        },
        clearForm(){
            this.secondPassword ={
                password :''
            };
        },
    },
    mounted() {
        this.initmap();
    },

    beforeDestroy() {
        console.log("timer：");
        console.log(this.timer);
        console.log("machine control beforeDestroy clearTime======");
        this.clearTime();        
    }, 
};
</script>

<style lang="less" scoped>
.el-dialog-custom {
    text-align: left;
    font-weight: 700;
    /deep/ .el-dialog__header {
        padding: 20px 40px 10px;
        border-bottom: 1px solid #f2f2f2;
    }
    /deep/ .el-dialog__footer {
        text-align: center;
        padding: 20px;
        border-top: 1px solid #f2f2f2;
    }
}
.machine-control-box {
    position: relative;
    .row1 {
        .row1-header-box {
            height: 38px;
            .row1-header-btn {
                font-size: 14px;
                float: right;
                button{
                    // width: 130px;
                    height: 38px;
                }
            }
        }

        .row1-body-box {
            width: 100%;
            height: 509px;
            margin: 10px 0 10px;
        }
    }

    .row3 {
        background: #fff;
        border-radius: 2px;
        .row3-header-label {
            height: 60px;
            line-height: 60px;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
        }

        .row3-body-box {
            .row3-body-table {
                .row3-body-table-box {
                    border: 1px solid #f2f2f2;

                    /deep/ .el-table__header tr th {
                        background: #fafafa;
                    }

                    .row3-table-operation {
                        display: flex;
                        justify-content: space-around;
                        color: #0079c1;

                        i {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            &:last-child {
                                color: #ff4d4f;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .custom-config-box {
        text-align: center;
        .custom-config-text {
            margin-top: 30px;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .custom-form-box {
        /deep/ .el-select {
            width: 70%;
        }
    }
}
</style>
<style lang="less">
.machine-control-box {
    .mapboxgl-popup-content {
        background: rgba(0,0,0,0);
        padding: 0;
    }
    .mark-content-box {
        color: #fff;
        text-align: left;
        padding: 20px;
        font-size: 16px;
        opacity: 0.9;
        border-radius: 2px;
        &>div {
            line-height: 25px;
        }

        .mark-content-title {
            font-size: 18px;
        }
    }
}
</style>