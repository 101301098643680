
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
const ch = {
  menu: {
    HomePage: '首页',
    Notification: '消息中心',
    ESEDevicePackage: "控制器打包",
    MyVehicle: "我的车辆",
    FactoryProduct: '工厂生产',
    MachineTypeManage: '车型管理',
    FaultManage: '故障管理',
    FaultAnalysis: '故障分析',
    RDMManage: 'RDM管理',
    RDMAssign: 'RDM分配',

    UserManagement: '授权管理',
    UserManagement_Org: '客户管理',
    UserManagement_User: '用户管理',
    UserManagement_UserGroup: '用户组管理',
    UserManagement_Role: '角色管理',
    UserManagement_VehicleGroup: '车辆分组管理',

    DataAnalysis: "数据分析",
    VoltageAnalysis: "电压分析",
    VehicleAnalysis: "车辆分析",
    EfficencyAnalysis: "效率分析",


    GeoFence: "电子围栏",
    GeoFenceManagement: "围栏设置",
    GeoFenceEvent: "围栏事件",

    BasicDataManage: "基础数据",
    PNManage: "品号管理",
    ParameterManage: "参数管理",
  },
  homepage: {
    showGeoFenceOnMap: '显示电子围栏',

    RDMNew: 'RDM使用状况',
    BatteryLevelLow: '车辆电压(低)',
    BatteryLevelVeryLow: '车辆电压(非常低)',
    VehicleFaultTrend: '车辆故障趋势',
    FaultStatistic: '故障统计',
    VehicleOverview: '车辆概述',
    MachineFault: '故障车辆',
    MachineFaultTips: '有故障的车辆个数',
    Vehicles: '车辆',
    Latest5minsVoltage:"电压监控(最近5分钟)",
    NoMahcinesOnline:"无在线车辆",
  },
  common: {
    welcome: '欢迎您',
    input_password: '密码:',
    input_new_password: '新密码:',
    check_password: '请输入密码',
    check_new_password: '请输入新密码',
    check_password_length: '新密码要求至少6个字符',

    selectCompany: "请选择公司",
    selectMachine: "请选择车辆",
    selectSoftware: "请选择软件",
    selectRDM: "请选择RDM",
    empty: "没有数据",
    company: "公司",
    comment: "备注",
    Tip: '提示',
    deleteComfirm: "确认要删除吗？",
    deleteComfirmTip: '部分数据异常未修复, 是否删除继续?',
    deleted: '已取消删除',
    operationSuccess: "操作成功",
    operationFailure: "操作失败",
    machineControlOperationFailure: "操作失败！可能得原因有:1.车辆不支持 2.车辆总线故障",
    operation: '操作',
    confirm: "确 定",
    cancel: "取 消",
    lat: "纬度",
    log: "经度",
    geo: "坐标",
    carNo: "车辆序列号",
    carType: "车辆类型",
    RDM: "RDM",
    modelNumber: "控制器信息",
    carState: "车辆状态",
    carState0: "离线",
    carState1: "在线",
    carState_online: '在线',
    carState_offline: '离线',
    carState_error: '故障',
    carState_fault: '故障',
    carKSIState_on: '上电',
    carKSIState_off: '断电',
    rdmSimState_on: '正常',
    rdmSimState_off: '停机',
    batteryVoltageStatus_normal: '正常',
    batteryVoltageStatus_warn: '警告',
    batteryVoltageStatus_error: '危险',
    carLockStatus_Lock: '锁定',
    carLockStatus_UnLock: '未锁定',
    lastOnlineTime: "最近在线时间",
    battery: "电池电压",
    faultCode: "错误码",
    geoFenceCmdLimit: "电子围栏限制",
    search: "查询",
    startTime: "开始时间",
    endTime: "结束时间",
    lastWeek: "最近一周",
    lastMonth: "最近一个月",
    all: "全选",
    today: "今天",
    thisWeek: "本周",
    thisMonth: "本月",
    thisYear: "今年",
    mapcode_China: '中国',
    mapcode_India: '印度',
    mapcode_Japan: '日本',
    mapcode_USA: '美国',
    mapcode_desc: '争议边界视角',
    switch_language: '中文',
    switch_language_ch: '切换为中文',
    switch_language_en: '切换为英文',
    logout: '退出登录',
    password: '修改密码',
    secondpassword:'修改二次验证密码',
    timezone: '修改时区',
    select_timezone: '选择时区:',
    version: '版本',
    pleaseChoose: '请选择...',
    required: '必填',
    success: '成功',
    change_password_success: '修改密码成功',
    change_password_error: '修改密码失败',
    change_timezone_success: '修改时区成功',
    change_timezone_error: '修改时区失败',
    change_language_error: '修改语言失败',
    switchCompany: "切换公司",
    noData: "无数据",
    yes:"是",
    no:"否",
    edit : '编辑',
    delete : '删除',
    clone : '克隆',
    vehicleDetail : '车辆详情',
    Upload : '上传',
    Uploading : '正在上传',
    ImportedTip1:'导入成功',
    ImportedTip2:'导入成功,但存在部分异常,异常条数',
    PictureRequire:'图片类型要求：jpeg、jpg、png',
    ToSelectImage:'请选择图片文件',
    FileRequire:'文件类型要求:',
    used:'已经被使用',
    Off:'关',
    On:'开',
    loadingMore:'加载更多',
    reset:'重置',
    startdate:'开始日期',
    enddate:'结束日期',
    chooseMachine:'选择车辆',
    chooseDate:'选择日期时间',
    Description:'描述',
    selectOnlyOne:'至多选择一个',
    CarOnlineCount: "车辆在线数量",
    CarOfflineCount: "车辆离线数量",
    CarTotalCount: "总数量",
    RDMOnlineCount: "RDM在线数量",
    RDMOfflineCount: "RDM离线数量",
    KSIOfflineCount: "车辆KSI断电数量",
    KSIOnlineCount: "车辆KSI上电个数",
    RDMConfigCount: "RDM已使用个数",
    RDMUnConfigCount: "RDM未使用个数",
    Page_of: "条,总",
    Pages: "条",
    SEQ:"序号",
    Hour:"小时",
    Day:"天",
    Second:"秒",
    Frequency:"次",
    Voltage:"伏",
    Time:"时间",
    Seconds:"秒",
    KSIOnTime:"KSI开启时间",
    KSIOffTime:"KSI关闭时间",
    TractionTime:"牵引时间",
    LiftingTime:"举重时间",
    WorkTime:"工作时间",
    FreeTime:"空闲时间",
    EfficiencyRate:"效率",
    SearchKeyWord:"请输入参数名称",
  },
  bizConfig: {
    DeviceImportTitle: 'E/SE系列控制打包',
    DeviceName: '控制器名称',
    OSVersion: 'OS版本号',
    NewOS: '新增OS',
    Remark: '描述',
    confirm: '确认',
    DeviceMenuFile: '控制器菜单文件',
    VCLCodFile: 'VCL文件',
    OSBSLFile: 'OS BSL文件',
    VCLBSLFile: 'VCL BSL文件',
    OnlyDeviceMenuFile: '只能上传1314生成的菜单xls文件(.xls)',
    OnlyVCLCodFile: '只能上传VCL代码文件(.vcl)',
    OnlyOSBSLFile: '只能上传设备OS程序文件(.bsl)',
    OnlyVCLBSLFile: '只能上传设备VCL程序文件(.bsl)',
    OnlyFileEDS: '只能上传EDS文件(.eds)',
    OnlyFileOS123: '只能上传CSV文件(.csv)',
    OnlyFileFault: '只能上传CSV文件(.csv)',
    OnlyFileCanObjectAddr: '只能上传CSV文件(.csv)',
    AddOs: '添加OS信息',
    AddFile: '添加文件',
    RemarkInformation: '备注信息',
    EDSFile: 'EDS文件',
    FileOS123: 'os123xesp文件',
    FaultFile: 'OS故障配置文件',
    CanObjectAddrFile: '自定义参数地址文件',
    save: '保存',
    cancle: '取消',
    generate: '生成',
    clear: '清除',
    NO:'编号',
    DsviceVersion:'设备版本',
    Operation:'操作',
    OSVerifyMessage:'请输入正确的OS版本号',  
    OSVerifyMessage2:'该OS版本已存在, 是否更新?', 
    OSVerifyMessage3:'成功，OS版本已经生成!', 
    OSResultMessage:'失败，OS版本生成失败!', 
    DeviceVerifyMessage:'该控制器名称已存在, 是否继续?',  
    DeviceParameterNullMessage:'部分参数地址为空,是否同时导出空地址参数？',
    PackageGenerateMessage:'正在生成新设备文件，请等待...',     
  },
  addMachine: {
    addMachine: '添加车辆',
    EditMachine: '编辑车辆',
    setp1: '车辆基本信息',
    setp2: '车辆设备配置',
    setp3: '车辆故障配置',
    setp4: '车辆监控配置',
    MachineSN: '车辆序列号',
    //MachineName: '车辆名称',
    MachineSNExited: '车辆序列号已存在',
    RDMNO: 'RDM序列号',
    voltageRange: "电压范围",
    voltageRangeRule: "请输入正确的电压范围",
    voltageRangeTypeRule: "电压范围值需要为整数",
    remark: '备注',
    picture: '图片',
    uploadPicture: '上传图片',
    Rebulid: '重新选择',
    support: '支持jpg，jpeg，png，JPG，PNG格式图片',
    Preview: '预览',
    tip: '滚动/滚动鼠标缩放/拖动图片  ',
    Submit: '提交',
    Return: '返回',
    Next: '下一步',
    Type: '类型',
    DeviceNodeId: 'NodeId',
    DeviceFile: '上传设备文件',
    Add: '添加',
    OnlyRDEVFile: '只能上传RDEV文件',
    OnlyEDEVFile: '只能上传EDEV文件',
    OnlyFDEVFile: '只能上传RDEV文件',
    exitNodeId: 'NodeId已存在！',
    AddDeviceDialogTitle: '添加设备配置',
    EditDeviceDialogTitle: '编辑设备配置',
    Previous: '上一步',
    Delete: '删除',
    faultSearch: '故障名称/编号',
    editFault: '编辑故障信息',
    faultName: '故障名称',
    faultDisplayName: '故障显示名称',
    monitorDialogTitle: '编辑监控参数信息',
    displayName: '显示名称',
    deviceNodeID: '设备',
    address: '参数名(地址)',
    frequency: '更新频率',
    openDataAnalysis: '开启数据分析',
    importYourTemplate: '请选择您要导入的模板',
    import: '导入',
    name: '名称',
    saveTemplate: '保存为模板',
    templateName: '模板名称',
    UsedAsTemplate: '保存模板配置',
    templateNameExited: '模板名已存在',
    FaultDisplayName: '故障显示名称',
    FaultName: '故障名称',
    FaultCode: '故障码',
    CANAddress: 'CAN 地址',
    Remark: '标记',
    Edit: '编辑',
    DisplayName: '展示名称',
    ParameterNameAddress: '参数名称（地址）',
    UpdateFrequency: '更新频率',
    DataAnalysis: '数据分析',
    DefaultMachineMonitor: '默认机器监视器:',
    MachineMonitor: '机器监视器：',
    NoMoreMonitor: '没有更多的设备监视器信息',
    addmonitor: '添加监视器',
    Device: '设备',
    Devices: '设备',
    List: '列表',
    FSeries: 'F系列',
    NodeId: '节点Id',
    FaultList: '故障列表',
    ImportFromTemplate: '从模板导入',
    IsDeleteData: '确定删除这条数据',
  },
  Error: {
    FileTypeError: '文件类型错误！',
    MonitorParameterError: '监控参数配置错误',
  },
  login: {
    login: '登录',
    language: 'EN',
    check_username: '请输入用户名',
    check_password1: '请输入密码',
    check_password2: '请输入至少6位字符',
    message1: '登录成功',
    message2: '用户名或密码不正确',
    input_username: '用户名',
    input_password: '密码',
    remember: "记住密码",
    title1: '欢迎使用RDS',
    title2: '智能监控,精准分析',
    CompanyLTD:'科蒂斯技术(苏州)有限公司.',
    AllRight:'版权所有.',
    SuICP:'苏ICP备',
    PublicNetWorkSecurity:'苏公安网备',
    SuUnit:'号',
  },
  notification: {
    MessageCenter: '消息中心',
    myInformation: '我的消息',
    markAllAsRead: '标记所有已读',
    markAsRead: '标记已读',
    remove: '移除',
    type_All: '全部',
    type_error: '错误',
    type_warning: '警告',
    type_notice: '通知',
    toMessageCenter: '消息中心',
    deleteAll: '删除全部',
    Noerrorinformation:"无错误消息",
    Nowarninginformation:"无警告消息",
    Nonoticeinformation:"无提示消息",
    Noinformation:"无消息",
    JustNow:"刚刚",
    MinutesAgo:" 分钟之前",
    HoursAgo:" 小时之前",
    DaysAgo:" 天之前",
  },
  myvehicle: {
    companylist: '公司列表',
    company: '公司',
    machine: '车辆',
    check_machinesn: '请输入车辆序列号',
    MachineSNExist: '车辆序列号已存在',
    select_rdmno: '请选择RDM序号',
    companyName: '公司名称',
    machineinformation: '车辆信息',
    addMachine: '新增车辆',
    groupManage: '车辆组管理',
    backList: '返回列表',
    vehicleInformation: '车辆信息',
    vehicleBasicInformation: '车辆基础信息',
    vehicleFault: '车辆故障',
    faultAffectTime: '故障影响时间',
    latestHalfHourBatteryVoltage: '近半小时电压',
    parameterMonitor: '参数监控',
    machineStatus: '车辆状态',
    rdmStatus: 'RDM状态',
    ksiStatus: 'KSI状态',
    simStatus: 'SIM状态',
    batteryStatus: '电压状态',
    chooseGroup: '选择车辆组',
    machineList: '车辆列表',
    batchEdit: '批量更新',
    batchUpgradeSoftware: '批量软件升级',
    releaseModel: '发布车型',
    machineType: '车辆类型',
    measurementClass: '测量类',
    physicalclass: '物理类',
    timeClass: '时间类',
    deviceClass: '器件类',
    switchClass: '开关',
    otherClass: '其他',
    deviceList: '设备列表',
    systemVersion: '系统版本',
    fieldVersion: '当前版本',
    latestVersion:'最新版本',
    parameterList:'参数列表',
    contralModeSeclect : '选择参数',
    parameterName : '参数名',
    minValue : '最小值',
    maxValue : '最大值',
    units : '单位',
    paramvalue : '参数值',
    notRange : '修改的值不在范围内！',
    noParam : '没有要修改的参数!',
    class : '分类',
    mix : '聚合',
    expand : '展开',
    collapse : '收起',
    memberof : '成员属于',
    goodCondition : '机器状况良好!',
    machineDetailInformation : '车辆基本信息',
    historyDataAnalysis : '历史数据分析',
    parameterQueryAndEdit : '参数查询与修改',
    machineControl : '车辆控制',
    machineUpgrade : '车辆升级',
    operationSuccessWaiting: "请等待或请重启车辆KSI",
    RemoteStatus:'车辆远程状态',
    changeRemoteStatus : '改变车辆远程状态',
    resetRemote : '重置远程',
    enableRemote : '开启远程',
    disableRemote : '关闭远程',
    RaiseUpStatus:'车辆起升状态',
    changeRaiseUpStatus : '改变车辆起升状态',
    enableRaiseUp : '开启起升',
    disableRaiseUp : '禁止起升',
    changeLimitlevelStatus : '改变车辆限度等级',
    limitedMachine : '车辆限速',
    //changeLockStatus : '改变车辆锁车状态',
    changeLockAndRaiseUpStatus : '改变车辆锁车和起升状态',
    lockMachineStatus : '锁车状态',
    unlockMachine : '解锁车辆',
    lockMachine : '车辆锁定',
    lockMachineAnddisableRaiseUp : '车辆锁定和禁止起升',
    unlockMachineAndenableRaiseUp : '解锁车辆和开启起升',
    machineGEOfenceEvent :'车辆地理围栏事件',
    eventTime : '事件事件',
    fenceName : '栅栏名称',
    tigger : '触发条件',
    command : '命令',
    //confirmUnlockMachine : '确认解锁车辆',
    //confirmlockMachine : '确认锁定车辆',
    confirmUnlockAndEnableRaiseUpMachine : '确认解锁车辆和开启起升',
    confirmlockAndDisableRaiseUpMachine : '确认锁定车辆和禁用起升',
    confirmEnableRemoteStatus : '确认开启远程操作功能',
    confirmDisableRemoteStatus : '确认关闭远程操作功能',
    confirmEnableRaiseUpStatus : '确认开启起升功能',
    confirmDisableRaiseUpStatus : '确认禁用起升功能',
    confirmResetRemoteStatus : '确认恢复所有远程控制状态到初始化状态',
    enter : '进入',
    leave : '离开',
    nolimitOperation : '解除限速',
    nolimitChoosed : '未限速',
    highlimit : '中速',
    middlelimit :'低速',
    lowlimit : '超低速',
    safeCheck : '请检查机器环境是否处于安全状态？',
    secondaryVerification : '二次密码验证',
    limitedSpeed : '限速',
    scuFail : 'SCU认证失败',
    scuOK : 'SCU认证成功',
    secondaryVerificationFail : '密码验证失败',
    carStatus : '状态',
    MachineUpgrade_success : '车辆升级成功',
    MachineUpgrade_fail : '车辆升级失败',
    selectSoftwareVersion : '选择软件版本',
    addNewSoftware : '新增软件',
    Uploaddevicefiles :'上传设备文件',
    Machine_locked : '车辆被锁定!!!',
    Machine_upgraded:'车辆升级中!!!',
    waiting : '请稍等......',
    lockby : '被{username}锁定',
    totalVersion : '共{total}个版本可用',
    fileUploadnotnull : '请至少上传一个设备的软件！',
    parameters:'个参数',
    softwreUpgradeStatus_Failed: '升级失败',
    softwreUpgradeStatus_Success:'升级成功',
    softwreUpgradeStatus_Processing:'升级中',
    softwreUpgradeStatus_TimeOut:'升级超时',
    softwreUpgradeStatus_SCU_Fail:'安全检查失败',
    softwreUpgradeStatus_RDMRefuse:'RDM未拒绝此次更新',
    softwreUpgradeStatus_File_Summary:'上传信息至RDM失败',
    softwreUpgradeStatus_Transfer_File:'上传升级包失败',
    softwreUpgradeStatus_RDMRefuseToUpagrade:'RDM拒绝更新',
    softwreUpgradeStatus_Upgrade_Interrupt:'升级中断',
    latestUpgradeFailed : '最近升级失败',
    faultStartTime : '故障发生时间',
    RDM_offline : 'RDM离线',
    KSI_ElectricOff : '车辆KSI未上电',
    lable_range: '范围值(单位)',
    machineType_Name: '车辆类型名称',
    TypeQRCode: '车型二维码',
    createMachineType: '发布车型',
    selectOneMachine: '最多选择一辆车',
    TypeNameExited: '车型名称已存在',
    loading_limit: '正在限速',
    loading_lock: '正在锁车',
    loading_unlock: '正在解锁车辆',
    loading_enableremote: '正在开启远程控制功能',
    loading_disableremote: '正在关闭远程控制功能',
    loading_enableraiseup: '正在开启起升',
    loading_disableraiseup: '正在禁止起升',
    loading_publishtype: '正在发布车型',
    loading_editParam: '正在修改参数',
    loading_queryParam: '正在查询参数',
    Fault_Statistic: '故障统计',
    Fault_Affect_Time: '故障处理时长(小时)',
    Parametric_Analysis: '参数分析',
    Machine_Efficiency: '设备效率',
    History_location: '历史轨迹',
    Device_Information: "设备详情",
    SendConfigurationInformationToRDMFail: '通知RDM更新配置信息失败！',
    machineDevice:"车辆设备信息",
    Selected:"已选择",
    DisableParameterQuery: '未授予查看参数的权限!',
    FaultForMachine:" 个车辆故障",
    FaultForController:" 个设备故障,设备名称:",
    KSIStatusComment:"RDM离线,非实时状态值",
    exportMachine: '导出车辆',
  },
  RDMMessage: {
    stopFailed: '停止获取参数值失败',
    uploadFailed: '通知上传参数值失败',
  },
  FactoryProduct: {
    manageMachineType: '发布车型管理',
    machineTypeName: '车辆类型名称',
    editMachineType: '编辑车型',
    deleteMachineType: '删除车型',
    emptyText: '没有可用的车型信息！',
    addMachineType: '新增车型',
    goTo: '请至',
    MachineTypeList: '车型列表',
    MachineType: '车型',
    loading_editMachineType: '正在修改车型',
    loading_CreateMachine: '正在创建车辆',
    card: '卡片',
    list: '列表',
    noMachineTypeText: '没有可用的车型，请与管理员联系！'
  },
  faultManage: {
    faultList: '故障列表',
    allfault: '所有故障',
    allfaultTips: '实时故障+历史故障+故障补充记录之和',
    realfault: '实时故障',
    realfaultTips: '系统自动记录的车辆故障，且故障未解决',
    systemfault: '历史故障',
    systemfaultTips: '系统自动记录的车辆故障，且故障已经解决',
    userfault: '故障补充记录',   
    userfaultTips: '系统未上报的，用户通过[新增故障记录]功能补充记录的故障(包含解决和未解决)', 
    addFaultRecord: '新增故障记录',
    record_user: '记录人员',
    resolved_user: '解决人员',
    faultCycle: '故障生命周期',
    active: '进行中',
    resolved: '已解决',
    faulttime: '故障时间',
    faultStartTime: '故障开始时间',
    faultEndTime: '故障结束时间',
    faultStatus: '故障状态',
    editFaultRecord: '编辑故障记录',
    durationAnalysis: '故障影响时长分析',
    tab_total_duration: '故障影响时长的整体分析',
    tab_daily_duration: '故障影响时长的按天统计',
    greaterthan_starttime: '结束时间必须大于开始时间',
    FaultFrequencyAnalysis: '故障发生次数分析',
    tab_total_frequency: '故障发生次数的整体分析',
    tab_daily_frequency: '故障发生次数的按天分析',
    Faultdetaillist: '故障列表',
    faultDate: '故障日期',
    faultFrequency: '故障次数',
    maintenance_record: '维护记录',
  },
  RDMManage: {
    RDMManage: 'RDM管理',
    Model: '类型',
    RDM_Detail: 'RDM详情',
    SoftwareManage: '软件管理',
    NewSoftware: '新增软件',
    SetDefault: '设为默认',
    IsDefault: '是否默认',
    UploadTime: '上传时间',
    Software: '软件',
    IsSetDefault: '确认设置选中软件为默认',
    IsSoftware: '软件已存在',
    NetworkStatus: '网络状态',
    Assign: '分配',
    Operation: '活动',
    Deactivate: '停机',
    Activate: '激活',
    confirmActivate: '确认申请复机',
    confirmDeactivate: '确认申请停机',
    chooseCompany: '选择公司',
    Add: '新增',
    Delete: '删除',
    Edit: '编辑',
    AssignRDM: '分配',
    ModelNumber: "型号",
    ProductNo: "品号",
    DateCode: "日期码",
    ICCID: "SIM卡ICCID",
    MISISDN: "SIM卡号",
    IMSI: "SIM卡IMSI",
    RDMNoExist: 'RDM序列号已存在',
    RDMDetail: 'RDM基本信息',
    RDMParameterManage: '参数查询与修改',
    RDMSoftwareManage: '升级升级',
    SoftwareVersion: '软件版本',
    SoftwareDesc: '软件描述',
    SoftwareUploadTime: '软件上传时间',
    ServiceProvider: '网络服务商',
    DataUsage: '数据使用',
    SoftwareConfig: '配置软件',
    SoftwareConfigStatus_Yes: '已配置软件',
    SoftwareConfigStatus_No: '未配置软件',
    NetWorkStatus_Normal: '网络正常',
    NetWorkStatus_Disabled: '网络已关闭',
    NetWorkStatus_No: '无网络',
    NetWorkStatus_Provider_CMCC: '移动',
    NetWorkStatus_Provider_CTCC: '电信',
    NetWorkStatus_Provider_CUCC: '联通',
    MachineBindStatus: '车辆配置状态',
    MachineBindStatus_Yes: '已配置车辆',
    MachineBindStatus_No: '未配置车辆',
    RDMMenu: 'RDM菜单',
    RDM_upgraded: 'RDM正在升级中!!!',
    RDM_locked: 'RDM被锁定!!!',
    latestUpgradeFailed: '最近升级失败',
    selectSoftwareVersion: '请选择软件版本',
    totalVersionCount: '共{total}个版本可用',
    software_detail: '软件详情',
    LatestUpdateTime: '最后更新时间',
    UpdateLimitTime: '三分钟之内只可操作一次',
    RDMPNManage: '品号管理',
    ProductType:"品号类型",
    ProductType_General:"通用",
    ProductType_Particular:"专用",
    selectPN:"选择品号",
    ThridCloudSupport:"是否支持第三方云",
    ThridCloudSupport_Yes:"支持",
    ThridCloudSupport_No:"不支持",
    PNExisted:"品号存在!",
    RDMSoftwareVersion:"RDM版本信息",
  },
  ...zhLocale
};
export default ch;
